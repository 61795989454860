import {
  ContactDto,
  IContactProduct
} from "api/GeneratedClients/ContactsClient";
import { Modal, Button as PreConButton } from "hcss-components";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as contactsActions,
  selectors as contactsSelectors
} from "modules/contacts";
import { Flex } from "hcss-core";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import MuiGrid from "@mui/material/Grid";
import styled from "styled-components";
import { formatPhoneNumber, TextOverflow } from "core";
import { StyledForm, TimesButton } from "./add-contact-list-modal";
import * as Yup from "yup";
import {
  CompanyBadge,
  CompanyDropdownFilter,
  CompanyFieldData,
  CompanyOption
} from "modules/schemas/components/fields/CompanyInput";
import { DropdownList } from "react-widgets";
import { strings } from "localization";
import { Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import { DropdownContainer } from "modules/schemas/components/fields/MultiSelectListInput";
import Alert from "@mui/material/Alert";
import { differenceBy, sortBy } from "lodash-es";
import { IMoveContactModalProps } from "../interfaces/IMoveContactModalProps";
import { IVendorListOption } from "../interfaces/IVendorListOption";

export const MoveContactModal = (props: IMoveContactModalProps) => {
  const { movingContact, vendor } = props;
  const [removingContactProducts, setRemovingContactProducts] = useState<
    IContactProduct[]
  >();
  const [contact, setContact] = useState(movingContact);
  const [
    selectedVendor,
    setSelectedVendor
  ] = useState<CompanyFieldData | null>();
  const dispatch = useDispatch();
  useEffect(() => {
    setContact(movingContact);
  }, [movingContact]);
  const history = useHistory();
  const showModal = useSelector(contactsSelectors.getShowMoveContactModal);
  const vendorDataOptions = useSelector(contactsSelectors.getVendorDataOptions);
  const options: IVendorListOption[] = vendorDataOptions
    .map(data => {
      return {
        display: `${data.code} - ${data.name}`,
        value: data,
        code: data.code,
        id: data.id,
        products: data.products
      };
    })
    .filter(op => op.id !== vendor?.id);
  const onCancelHandler = () => {
    setRemovingContactProducts([]);
    setSelectedVendor(null);
    dispatch(contactsActions.setShowMoveContactModal(false));
  };

  const onMoveHandler = (contact: ContactDto) => {
    const newVendorId = selectedVendor?.id;
    const oldVendorId = contact.companyId;
    const removingContactProductIds = removingContactProducts?.map(
      p => p.productTypeId
    );
    contact.companyId = newVendorId;
    contact.vendorLocationId = undefined;
    let oldProducts: IContactProduct[] = [];
    let newProducts: IContactProduct[] = [];
    if (contact.products) {
      oldProducts = contact.products;
      newProducts = contact.products?.filter(
        cp => !removingContactProductIds?.includes(cp.productTypeId)
      );
    }
    contact.products = newProducts;
    if (!!!(selectedVendor && oldVendorId && newVendorId)) return;
    setSelectedVendor(null);
    setRemovingContactProducts([]);
    dispatch(
      contactsActions.moveContact.request({
        oldVendorId: oldVendorId,
        newVendorId: newVendorId,
        oldProducts: oldProducts,
        newProducts: newProducts,
        contact: contact,
        history
      })
    );
  };
  const onSelectCompanyHandler = (data: IVendorListOption) => {
    const vendorDestinationProducts = data.products.map(vp => ({
      ...vp.product,
      vendorProductId: vp.id,
      productTypeId: vp.product?.id
    }));
    const removingContactProducts = differenceBy(
      contact.products,
      vendorDestinationProducts,
      "productTypeId"
    );
    const sortedRemovingContactProducts = sortBy(
      removingContactProducts,
      p => p.code
    );
    setRemovingContactProducts(sortedRemovingContactProducts);
    setSelectedVendor(data.value);
  };

  return (
    <Modal
      show={showModal}
      onHide={
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {}
      }
    >
      <Modal.Header style={{ paddingBottom: "0px" }}>
        <MuiGrid container justifyContent="space-between">
          <h4
            style={{
              margin: "0.5rem",
              width: "inherit",
              display: "inline-flex",
              whiteSpace: "nowrap",
              alignItems: "end"
            }}
          >
            {strings.contactManagement.contactListMoveContactModal.moveContact}
            &nbsp;
            <Flex style={{ whiteSpace: "nowrap", maxWidth: "80%" }}>
              <TextOverflow
                style={{ maxWidth: "fit-content", fontWeight: "600" }}
              >
                {movingContact.firstName}&nbsp;{movingContact.lastName}
              </TextOverflow>
            </Flex>
          </h4>
          <TimesButton
            type="button"
            className="close"
            onClick={() => {
              onCancelHandler();
            }}
          >
            <span aria-hidden="true">×</span>
          </TimesButton>
        </MuiGrid>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={contact}
          validationSchema={YupValidationSchema}
          onSubmit={values => {
            onMoveHandler(values);
          }}
        >
          {(formik: any) => (
            <StyledForm>
              <MuiGrid
                container
                item
                xs={12}
                paddingBottom="1rem"
                justifyContent="space-between"
                flexDirection="column"
                paddingX="2.5rem"
                gap="2rem"
              >
                <MuiGrid item xs={12}>
                  <p>
                    {
                      strings.contactManagement.contactListMoveContactModal
                        .fromCompany
                    }
                  </p>
                  <Flex alignItems="center" justifyContent="flex-start">
                    <CompanyBadge vendor={vendor} />
                  </Flex>
                </MuiGrid>
                <MuiGrid item xs={12}>
                  <p>
                    {
                      strings.contactManagement.contactListMoveContactModal
                        .toCompany
                    }
                  </p>
                  <Flex
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ position: "relative" }}
                  >
                    <CompanyBadge vendor={selectedVendor} />
                    <div id="arrow-dropdown-container">
                      <DropdownContainer>
                        <DropdownList
                          id="arrow-dropdown"
                          filter={(option, search) =>
                            CompanyDropdownFilter(option, search)
                          }
                          textField="display"
                          valueField="code"
                          value={selectedVendor}
                          data={options}
                          onChange={onSelectCompanyHandler}
                          messages={{
                            emptyList:
                              strings.projects.setup.message.emptyCompanyList
                          }}
                          itemComponent={({ item }) => (
                            <CompanyOption {...item.value} />
                          )}
                        />
                      </DropdownContainer>
                    </div>
                  </Flex>
                </MuiGrid>
                <Divider orientation="horizontal" flexItem />
              </MuiGrid>
              <MuiGrid container item xs={12} columnSpacing={2}>
                <MuiGrid item xs={6} textAlign="center">
                  <StyledTextField
                    id="cellPhoneNumber"
                    type="text"
                    margin="normal"
                    label="Cell Number"
                    error={
                      formik.touched.cellPhoneNumber &&
                      !!formik.errors.cellPhoneNumber
                    }
                    {...formik.getFieldProps("cellPhoneNumber")}
                    value={formatPhoneNumber(
                      formik.values.cellPhoneNumber
                        ? formik.values.cellPhoneNumber
                        : ""
                    )}
                    helperText={formik.errors.cellPhoneNumber}
                  />
                </MuiGrid>
                <MuiGrid item xs={6} textAlign="center">
                  <StyledTextField
                    id="phoneNumber"
                    type="text"
                    margin="normal"
                    label="Phone Number"
                    error={
                      formik.touched.phoneNumber && !!formik.errors.phoneNumber
                    }
                    {...formik.getFieldProps("phoneNumber")}
                    value={formatPhoneNumber(
                      formik.values.phoneNumber ? formik.values.phoneNumber : ""
                    )}
                    helperText={formik.errors.phoneNumber}
                  />
                </MuiGrid>
              </MuiGrid>
              <MuiGrid container item xs={12} columnSpacing={2}>
                <MuiGrid item xs={12} textAlign="center">
                  <StyledTextField
                    id="emailAddress"
                    margin="normal"
                    label="Email"
                    error={
                      formik.touched.emailAddress &&
                      !!formik.errors.emailAddress
                    }
                    helperText={formik.errors.emailAddress}
                    {...formik.getFieldProps("emailAddress")}
                  />
                </MuiGrid>
              </MuiGrid>
              <MuiGrid item xs={12}>
                {removingContactProducts && removingContactProducts.length > 0 && (
                  <Alert severity="warning">
                    <div style={{ paddingBottom: "0.5rem" }}>
                      {removingContactProducts.length === 1
                        ? strings.contactManagement.contactListMoveContactModal
                            .removingOneProductAlert
                        : strings.contactManagement.contactListMoveContactModal
                            .removingProductsAlert}
                      <span
                        style={{ fontWeight: "bold", marginLeft: "0.5rem" }}
                      >
                        {removingContactProducts.map(p => p.code).join(", ")}
                      </span>
                    </div>
                  </Alert>
                )}
              </MuiGrid>
              <MuiGrid item xs={12} style={{ paddingTop: "2rem" }}>
                <MuiGrid container justifyContent="space-between">
                  <MuiGrid item>
                    <Button onClick={onCancelHandler} color="secondary">
                      {
                        strings.contactManagement.contactListMoveContactModal
                          .buttonCancel
                      }
                    </Button>
                  </MuiGrid>
                  <MuiGrid item>
                    <Button
                      data-testid="move-contact-button"
                      type="submit"
                      disabled={!selectedVendor || !showModal}
                    >
                      {
                        strings.contactManagement.contactListMoveContactModal
                          .buttonMove
                      }
                    </Button>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            </StyledForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

const YupValidationSchema = Yup.object({
  emailAddress: Yup.string().email("Invalid email address")
});

const StyledTextField = styled(TextField)`
  height: 7rem;
  width: 100%;
`;

export const MoveContactSuccessNotification = (
  vendorId: string,
  history: any
) => {
  const onClickHandler = () => {
    history.replace(`/contacts/${vendorId}`);
  };
  return (
    <PreConButton onClick={onClickHandler}>
      {strings.contactManagement.contactList.visitContact}
    </PreConButton>
  );
};
