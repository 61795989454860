import { Badge } from "@mui/material";
import { FeatureFlag } from "core";
import { Item, MenuSection } from "core/components/hcss-navigation";
import { ConcreteColors, Icon, OverlayTrigger, Tooltip } from "hcss-components";
import { strings } from "localization";
import { usePermissions, useUserAndCompanyLicense } from "modules/account";
import { useFeatureFlags } from "modules/configurationSettings/use-feature-flags";
import { SubscriptionStatus, selectors } from "modules/email-subscriptions";
import { default as React, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import { paths } from "../../../../projects/views/paths";
import { HideInSandbox } from "core/util/sandbox";
import { HeaderSectionWithBackButton } from "../../common/header-section-with-back-button";

export const FailedSubscriptionsBadge = () => {
  const subscriptions = useSelector(selectors.getSubscriptions);

  const stopedCount = useMemo(
    () =>
      subscriptions.reduce(
        (acc, curr) => acc + +(curr.status === SubscriptionStatus.STOPPED),
        0
      ),
    [subscriptions]
  );

  return (
    <OverlayTrigger
      placement="right"
      delayShow={400}
      overlay={
        <Tooltip id="tool-tip">
          <TooltipStyling />
          {
            strings.layout.menu.projectTracking.subscriptions
              .stoppedSubscriptions
          }
        </Tooltip>
      }
    >
      <Badge
        data-testid="navitem-estimateAnalysis-subscription-setup-badge"
        color={"error"}
        badgeContent={stopedCount}
        max={999}
        sx={{
          ".MuiBadge-badge": { transform: "translate(20%, -50%)" }
        }}
      />
    </OverlayTrigger>
  );
};

export const CustomizeSetupContext = () => {
  const path = useLocation().pathname;
  const { companyLicense } = useUserAndCompanyLicense();
  const isLimitedCompany = companyLicense.isLimitedLicense;
  const permissions = usePermissions();
  const {
    isFeatureEnabled: isFrontEndEmailSubscriptionEnabled
  } = useFeatureFlags(FeatureFlag.FrontEndEmailSubscription);

  return (
    <div
      className="estimate-navigation-context"
      data-testid="customize-setup-context"
    >
      <HeaderSectionWithBackButton backPath="/projects">
        <div>{strings.layout.menu.projectTracking.customize}</div>
      </HeaderSectionWithBackButton>
      <MenuSection>
        {menuStyle => (
          <ItemContainer style={menuStyle}>
            <Item
              data-testid="navitem-estimateAnalysis-estimateDetails"
              key="estimate-details"
              text={strings.projects.list.title}
              isSelected={path === paths.setup}
              to={paths.setup}
            />
            <Item
              data-testid="navitem-estimateAnalysis-keyIndicators"
              key="key-indicators"
              text={strings.projects.setup.heavybidIntegration}
              isSelected={path === paths.setupHBIntegration}
              to={paths.setupHBIntegration}
              after={
                <Icon
                  name="product-heavybid"
                  style={{
                    marginRight: "0.5em",
                    color: "rgb(0, 150, 57)"
                  }}
                />
              }
            />
            {isFrontEndEmailSubscriptionEnabled && (
              <HideInSandbox>
                <Item
                  isLimitedCompany={isLimitedCompany}
                  data-testid="navitem-estimateAnalysis-subscription-setup"
                  key="subscription-setup"
                  text={"Subscription Setup"}
                  isSelected={path === paths.subscriptionSetup}
                  after={<FailedSubscriptionsBadge />}
                  to={paths.subscriptionSetup}
                />
              </HideInSandbox>
            )}
            {permissions.contactWrite && (
              <Item
                isLimitedCompany={isLimitedCompany}
                data-testid="navitem-estimateAnalysis-payitem-library"
                key="payitem-library"
                text={"Pay Item Library"}
                isSelected={path === paths.payItemLibrary}
                to={paths.payItemLibrary}
              />
            )}
          </ItemContainer>
        )}
      </MenuSection>
    </div>
  );
};

const ItemContainer = styled.div`
  font-size: 14px;
  margin-right: 14px;
  color: ${props => props.theme.colors.neutral[0]};
`;

const TooltipStyling = createGlobalStyle`
  .tooltip {
    background-color: ${ConcreteColors.gray700};
    margin: 0;
    border-radius: 4px;
    pointer-events:none;
    .tooltip-arrow {
      display: none;
    }
    
    .tooltip-inner {
      background-color: transparent;
      font-weight: 500;
      font-size: 13px;
    }
  }
`;
