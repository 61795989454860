import { keyBy, sortBy } from "lodash-es";
import { createSelector } from "reselect";
import produce from "immer";
import {
  ActionType,
  createAction,
  createAsyncAction,
  getType
} from "typesafe-actions";
import {
  ContactDto,
  ICompanyTypeDto,
  IContactDto,
  IMinorityStatusDto,
  IMinorityTypeDto,
  IProductTypeDto,
  IVendorDtoV1Response,
  MinorityStatusDto,
  VendorDtoV1Response,
  IGetContactSearchDto,
  CompanyTypeDto
} from "api/GeneratedClients/ContactsClient";
import {
  InteractionHistoryLogDetailedDto,
  CreateInteractionHistoryLogDto,
  UpdateInteractionHistoryLogDto,
  DeleteInteractionHistoryLogDto
} from "api/GeneratedClients/contacts";
import { CompanyFieldData } from "modules/schemas/components/fields/CompanyInput";
import { CreateCompanyMode } from "./components/vendor-list-modal";
import { RouterChildContext } from "react-router-dom";
import FileSaver from "file-saver";
export const STATE_KEY = "contacts";

// Models
export interface State {
  vendorList: IVendorDtoV1Response[];
  minorityTypes: IMinorityTypeDto[];
  productTypes: IProductTypeDto[];
  companyTypes: ICompanyTypeDto[];
  searchResults: IGetContactSearchDto[];
  loading: boolean;
  loaded: boolean;
  searching: boolean;
  searched: boolean;
  showSearchModal: boolean;
  errors: string[];
  showModal: boolean;
  showMoveContactModal: boolean;
  interactionHistoryLogsForOneContact: InteractionHistoryLogDetailedDto[];
  interactionsLoading: boolean;
  interactionsLoaded: boolean;
  showInteractionsModal: boolean;
  showAddInteractionModal: boolean;
}

export type ContactPayLoadState = Omit<
  State,
  | "loading"
  | "loaded"
  | "searchResults"
  | "searching"
  | "searched"
  | "showModal"
  | "showSearchModal"
  | "showMoveContactModal"
  | "showInteractionsModal"
  | "showAddInteractionModal"
  | "errors"
>;
export interface StateSlice {
  [STATE_KEY]: State;
}

// Actions
export const actions = {
  loadContactsState: createAsyncAction(
    "CONTACTS/LOAD_REQUEST",
    "CONTACTS/LOAD_SUCCESS",
    "CONTACTS/LOAD_FAILURE"
  )<void, void, Error>(),

  loadVendorsState: createAsyncAction(
    "CONTACTS/LOAD_VENDORS_REQUEST",
    "CONTACTS/LOAD_VENDORS_SUCCESS",
    "CONTACTS/LOAD_VENDORS_FAILURE"
  )<void, IVendorDtoV1Response[], Error>(),

  loadMinorityTypes: createAsyncAction(
    "CONTACTS/LOAD_MINORITY_TYPES_REQUEST",
    "CONTACTS/LOAD_MINORITY_TYPES_SUCCESS",
    "CONTACTS/LOAD_MINORITY_TYPES_FAILURE"
  )<void, IMinorityTypeDto[], Error>(),

  loadProductTypes: createAsyncAction(
    "CONTACTS/LOAD_PRODUCT_TYPES_REQUEST",
    "CONTACTS/LOAD_PRODUCT_TYPES_SUCCESS",
    "CONTACTS/LOAD_PRODUCT_TYPES_FAILURE"
  )<void, IProductTypeDto[], Error>(),

  loadCompanyTypes: createAsyncAction(
    "CONTACTS/LOAD_COMPANY_TYPES_REQUEST",
    "CONTACTS/LOAD_COMPANY_TYPES_SUCCESS",
    "CONTACTS/LOAD_COMPANY_TYPES_FAILURE"
  )<void, ICompanyTypeDto[], Error>(),

  loadVendorsReport: createAsyncAction(
    "CONTACTS/LOAD_VENDORS_REPORT_REQUEST",
    "CONTACTS/LOAD_VENDORS_REPORT_SUCCESS",
    "CONTACTS/LOAD_VENDORS_REPORT_FAILURE"
  )<
    {
      fileSaver: {
        default: typeof FileSaver;
        saveAs(
          data: string | Blob,
          filename?: string | undefined,
          options?: FileSaver.FileSaverOptions | undefined
        ): void;
        saveAs(
          data: string | Blob,
          filename?: string | undefined,
          disableAutoBOM?: boolean | undefined
        ): void;
      };
      reportType: string;
    },
    void,
    Error
  >(),

  setSelectedVendor: createAction("CONTACTS/SET_SELECTED_VENDOR", resolve => {
    return (vendorId: string) => resolve({ vendorId });
  }),

  setShowModal: createAction("CONTACT/SET_SHOW_MODAL", resolve => {
    return (modalState: boolean) => resolve({ modalState });
  }),

  createVendor: createAsyncAction(
    "CONTACTS/SAVE_VENDOR_REQUEST",
    "CONTACTS/SAVE_VENDOR_SUCCESS",
    "CONTACTS/SAVE_VENDOR_FAILURE"
  )<
    { vendor: IVendorDtoV1Response; history: History; mode: CreateCompanyMode },
    VendorDtoV1Response,
    Error
  >(),

  updateVendor: createAsyncAction(
    "CONTACTS/UPDATE_VENDOR_REQUEST",
    "CONTACTS/UPDATE_VENDOR_SUCCESS",
    "CONTACTS/UPDATE_VENDOR_FAILURE"
  )<
    {
      vendor: IVendorDtoV1Response;
      history: RouterChildContext["router"]["history"];
    },
    VendorDtoV1Response,
    Error
  >(),

  deleteVendor: createAsyncAction(
    "CONTACTS/DELETE_VENDOR_REQUEST",
    "CONTACTS/DELETE_VENDOR_SUCCESS",
    "CONTACTS/DELETE_VENDOR_FAILURE"
  )<string, string, Error>(),

  addContact: createAsyncAction(
    "CONTACTS/ADD_CONTACT_REQUEST",
    "CONTACTS/ADD_CONTACT_SUCCESS",
    "CONTACTS/ADD_CONTACT_FAILURE"
  )<
    { contact: IContactDto; vendor: IVendorDtoV1Response },
    { contact: IContactDto; vendor: IVendorDtoV1Response },
    Error
  >(),

  addContactFromProject: createAsyncAction(
    "CONTACTS/ADD_CONTACT_FROM_PROJECT_REQUEST",
    "CONTACTS/ADD_CONTACT_FROM_PROJECT_SUCCESS",
    "CONTACTS/ADD_CONTACT_FROM_PROJECT_FAILURE"
  )<
    {
      contact: IContactDto;
      vendor: IVendorDtoV1Response;
      updateInput: (contact: IContactDto, vendor: IVendorDtoV1Response) => void;
      setShowCreateContactModal: (showModal: boolean) => void;
      setSelectedCompany: React.Dispatch<
        React.SetStateAction<CompanyFieldData | null | undefined>
      >;
    },
    { contact: IContactDto; vendor: IVendorDtoV1Response },
    Error
  >(),

  updateContact: createAsyncAction(
    "CONTACTS/UPDATE_CONTACT_REQUEST",
    "CONTACTS/UPDATE_CONTACT_SUCCESS",
    "CONTACTS/UPDATE_CONTACT_FAILURE"
  )<
    { contact: IContactDto; vendor: IVendorDtoV1Response },
    { contact: IContactDto; vendor: IVendorDtoV1Response },
    Error
  >(),

  deleteContact: createAsyncAction(
    "CONTACTS/DELETE_CONTACT_REQUEST",
    "CONTACTS/DELETE_CONTACT_SUCCESS",
    "CONTACTS/DELETE_CONTACT_FAILURE"
  )<
    { contact: IContactDto; vendorId: string },
    { contact: IContactDto; vendorId: string },
    Error
  >(),

  moveContact: createAsyncAction(
    "CONTACTS/MOVE_CONTACT_REQUEST",
    "CONTACTS/MOVE_CONTACT_SUCCESS",
    "CONTACTS/MOVE_CONTACT_FAILURE"
  )<
    {
      contact: ContactDto;
      oldVendorId: string;
      newVendorId: string;
      history: History;
    },
    {
      contact: IContactDto;
      oldVendorId: string;
      newVendorId: string;
    },
    Error
  >(),

  loadInteractionHistoryLogsByVendorContactId: createAsyncAction(
    "CONTACTS/LOAD_INTERACTION_HISTORY_LOGS_REQUEST",
    "CONTACTS/LOAD_INTERACTION_HISTORY_LOGS_SUCCESS",
    "CONTACTS/LOAD_INTERACTION_HISTORY_LOGS_FAILURE"
  )<string, InteractionHistoryLogDetailedDto[], Error>(),

  createInteractionHistoryLog: createAsyncAction(
    "CONTACTS/CREATE_INTERACTION_HISTORY_LOG_REQUEST",
    "CONTACTS/CREATE_INTERACTION_HISTORY_LOG_SUCCESS",
    "CONTACTS/CREATE_INTERACTION_HISTORY_LOG_FAILURE"
  )<CreateInteractionHistoryLogDto, InteractionHistoryLogDetailedDto, Error>(),

  updateInteractionHistoryLog: createAsyncAction(
    "CONTACTS/UPDATE_INTERACTION_HISTORY_LOG_REQUEST",
    "CONTACTS/UPDATE_INTERACTION_HISTORY_LOG_SUCCESS",
    "CONTACTS/UPDATE_INTERACTION_HISTORY_LOG_FAILURE"
  )<
    {
      interactionHistoryLog: UpdateInteractionHistoryLogDto;
      id: string;
      contactId: string;
    },
    { interactionHistoryLog: UpdateInteractionHistoryLogDto; id: string },
    Error
  >(),

  deleteInteractionHistoryLog: createAsyncAction(
    "CONTACTS/DELETE_INTERACTION_HISTORY_LOG_REQUEST",
    "CONTACTS/DELETE_INTERACTION_HISTORY_LOG_SUCCESS",
    "CONTACTS/DELETE_INTERACTION_HISTORY_LOG_FAILURE"
  )<
    {
      interactionHistoryLog: DeleteInteractionHistoryLogDto;
      id: string;
      contactId: string;
    },
    string,
    Error
  >(),

  updateLastInteractionDate: createAsyncAction(
    "CONTACTS/UPDATE_LAST_INTERACTION_REQUEST",
    "CONTACTS/UPDATE_LAST_INTERACTION_SUCCESS",
    "CONTACTS/UPDATE_LAST_INTERACTION_FAILURE"
  )<
    { vendorId: string; contactId: string },
    { vendorId: string; contactId: string },
    Error
  >(),

  updateProductTypes: createAsyncAction(
    "CONTACTS/UPDATE_PRODUCT_TYPES_REQUEST",
    "CONTACTS/UPDATE_PRODUCT_TYPES_SUCCESS",
    "CONTACTS/UPDATE_PRODUCT_TYPES_FAILURE"
  )<IProductTypeDto, IProductTypeDto[], Error>(),

  addMinorityStatus: createAsyncAction(
    "CONTACTS/ADD_MINORITY_STATUS_REQUEST",
    "CONTACTS/ADD_MINORITY_STATUS_SUCCESS",
    "CONTACTS/ADD_MINORITY_STATUS_FAILURE"
  )<
    { minorityStatus: IMinorityStatusDto; vendorId: string },
    { minorityStatus: MinorityStatusDto; vendorId: string },
    Error
  >(),

  updateMinorityStatus: createAsyncAction(
    "CONTACTS/UPDATE_MINORITY_STATUS_REQUEST",
    "CONTACTS/UPDATE_MINORITY_STATUS_SUCCESS",
    "CONTACTS/UPDATE_MINORITY_STATUS_FAILURE"
  )<
    { minorityStatus: IMinorityStatusDto; vendorId: string; statusId: string },
    {
      minorityStatus: IMinorityStatusDto;
      vendorId: string;
      statusId: string;
    },
    Error
  >(),

  deleteMinorityStatus: createAsyncAction(
    "CONTACTS/DELETE_MINORITY_STATUS_REQUEST",
    "CONTACTS/DELETE_MINORITY_STATUS_SUCCESS",
    "CONTACTS/DELETE_MINORITY_STATUS_FAILURE"
  )<
    { minorityStatus: IMinorityStatusDto; vendorId: string },
    { vendorId: string; statusId: string },
    Error
  >(),

  updateCompanyTypes: createAsyncAction(
    "CONTACTS/UPDATE_COMPANY_TYPES_REQUEST",
    "CONTACTS/UPDATE_COMPANY_TYPES_SUCCESS",
    "CONTACTS/UPDATE_COMPANY_TYPES_FAILURE"
  )<ICompanyTypeDto, ICompanyTypeDto[], Error>(),

  searchContacts: createAsyncAction(
    "CONTACTS/SEARCH_CONTACT_REQUEST",
    "CONTACTS/SEARCH_CONTACT_SUCCESS",
    "CONTACTS/SEARCH_CONTACT_FAILURE"
  )<string, IGetContactSearchDto[], Error>(),

  setShowSearchModal: createAction("CONTACTS/SEARCH_MODAL_TOGGLE", resolve => {
    return (isOpen: boolean) => resolve({ isOpen });
  }),

  setShowMoveContactModal: createAction(
    "CONTACTS/MOVE_MODAL_TOGGLE",
    resolve => {
      return (isOpen: boolean) => resolve({ isOpen });
    }
  ),

  setShowInteractionsModal: createAction(
    "CONTACTS/INTERACTIONS_MODAL_TOGGLE",
    resolve => {
      return (isOpen: boolean) => resolve({ isOpen });
    }
  ),

  setShowAddInteractionModal: createAction(
    "CONTACTS/ADD_INTERACTION_MODAL_TOGGLE",
    resolve => {
      return (isOpen: boolean) => resolve({ isOpen });
    }
  ),

  addMinorityType: createAsyncAction(
    "CONTACTS/ADD_MINORITYTYPE_REQUEST",
    "CONTACTS/ADD_MINORITYTYPE_SUCCESS",
    "CONTACTS/ADD_MINORITYTYPE_FAILURE"
  )<IMinorityTypeDto, IMinorityTypeDto, Error>(),

  updateMinorityType: createAsyncAction(
    "CONTACTS/UPDATE_MINORITYTYPE_REQUEST",
    "CONTACTS/UPDATE_MINORITYTYPE_SUCCESS",
    "CONTACTS/UPDATE_MINORITYTYPE_FAILURE"
  )<
    { minorityType: IMinorityTypeDto; minorityTypeId: string },
    { minorityType: IMinorityTypeDto; minorityTypeId: string },
    Error
  >(),

  addProductType: createAsyncAction(
    "CONTACTS/ADD_PRODUCTTYPE_REQUEST",
    "CONTACTS/ADD_PRODUCTTYPE_SUCCESS",
    "CONTACTS/ADD_PRODUCTTYPE_FAILURE"
  )<IProductTypeDto, IProductTypeDto, Error>(),

  updateProductType: createAsyncAction(
    "CONTACTS/UPDATE_PRODUCTTYPE_REQUEST",
    "CONTACTS/UPDATE_PRODUCTTYPE_SUCCESS",
    "CONTACTS/UPDATE_PRODUCTTYPE_FAILURE"
  )<
    { productType: IProductTypeDto; productTypeId: string },
    { productType: IProductTypeDto; productTypeId: string },
    Error
  >(),

  syncContactToProjects: createAsyncAction(
    "CONTACTS/SYNC_CONTACT_TO_PROJECT_REQUEST",
    "CONTACTS/SYNC_CONTACT_TO_PROJECT_SUCCESS",
    "CONTACTS/SYNC_CONTACT_TO_PROJECT_FAILURE"
  )<IContactDto, void, Error>()
};

export type ContactActions = ActionType<typeof actions>;

const initialState: State = {
  vendorList: [],
  minorityTypes: [],
  productTypes: [],
  companyTypes: [],
  searchResults: [],
  loading: false,
  loaded: false,
  searching: false,
  searched: false,
  showSearchModal: false,
  showModal: false,
  showMoveContactModal: false,
  interactionHistoryLogsForOneContact: [],
  interactionsLoading: false,
  interactionsLoaded: false,
  showInteractionsModal: false,
  showAddInteractionModal: false,
  errors: []
};

// Reducer
export const reducer = (state = initialState, action: ContactActions) => {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loadContactsState.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.loadContactsState.success): {
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.loadContactsState.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error loading contacts: ${action.payload.message}`);
        break;
      }

      case getType(actions.loadVendorsState.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.loadVendorsState.success): {
        draft.loading = false;
        draft.loaded = true;
        const vendorList = action.payload;
        draft.vendorList = filterDuplicateProducts(vendorList);
        break;
      }

      case getType(actions.loadVendorsState.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error loading vendors: ${action.payload.message}`);
        break;
      }

      case getType(actions.loadProductTypes.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.loadProductTypes.success): {
        const productTypes = action.payload;
        draft.productTypes = productTypes;
        break;
      }

      case getType(actions.loadProductTypes.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error loading scopes of work: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.loadMinorityTypes.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.loadMinorityTypes.success): {
        const payload = action.payload;
        draft.minorityTypes = payload;
        break;
      }

      case getType(actions.loadMinorityTypes.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error loading minority types: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.loadCompanyTypes.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.loadCompanyTypes.success): {
        const payload = action.payload;
        draft.companyTypes = payload;
        break;
      }

      case getType(actions.loadCompanyTypes.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error loading company types: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.loadVendorsReport.request): {
        break;
      }
      case getType(actions.loadVendorsReport.success): {
        break;
      }
      case getType(actions.loadVendorsReport.failure): {
        draft.errors.push(
          `Error loading vendors report: ${action.payload.message}`
        );
        break;
      }
      case getType(actions.setShowModal): {
        const { modalState } = action.payload;
        draft.showModal = modalState;
        break;
      }

      case getType(actions.setShowMoveContactModal): {
        const { isOpen } = action.payload;
        draft.showMoveContactModal = isOpen;
        break;
      }

      case getType(actions.setShowInteractionsModal): {
        const { isOpen } = action.payload;
        draft.showInteractionsModal = isOpen;
        break;
      }

      case getType(actions.setShowAddInteractionModal): {
        const { isOpen } = action.payload;
        draft.showAddInteractionModal = isOpen;
        break;
      }

      case getType(actions.updateProductTypes.success): {
        const productTypes = action.payload;
        draft.productTypes = productTypes;
        break;
      }
      case getType(actions.createVendor.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.createVendor.success): {
        draft.vendorList.push(action.payload);
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.createVendor.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error updating vendor: ${action.payload.message}`);
        break;
      }

      case getType(actions.updateVendor.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.updateVendor.success): {
        const updatedVendor = action.payload;
        const vendors = draft.vendorList.filter(v => v.id !== updatedVendor.id);
        draft.vendorList = [...vendors, updatedVendor];
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.updateVendor.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error updating vendor: ${action.payload.message}`);
        break;
      }

      case getType(actions.deleteVendor.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.deleteVendor.success): {
        const deletedId = action.payload;
        const vendors = draft.vendorList.filter(v => v.id !== deletedId);
        draft.vendorList = vendors;
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.deleteVendor.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error deleting vendor: ${action.payload.message}`);
        break;
      }

      case getType(actions.addContact.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.addContact.success): {
        const { vendor, contact } = action.payload;
        const vendorId = vendor.id;
        const foundVendor = draft.vendorList.find(v => v.id === vendorId);
        if (contact.isMainContact && foundVendor) {
          if (foundVendor.contacts) {
            const currentMainContactIndex = foundVendor.contacts.findIndex(
              c => c.isMainContact
            );
            let vendorHasMainContact = false;
            if (currentMainContactIndex !== -1) vendorHasMainContact = true;
            if (vendorHasMainContact)
              foundVendor.contacts[
                currentMainContactIndex
              ].isMainContact = false;
          }
        }
        if (foundVendor) {
          foundVendor.contacts.push(contact);
        }

        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.addContact.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error adding contact: ${action.payload.message}`);
        break;
      }

      case getType(actions.addContactFromProject.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.addContactFromProject.success): {
        const { vendor, contact } = action.payload;
        const vendorId = vendor.id;
        const foundVendor = draft.vendorList.find(v => v.id === vendorId);
        if (contact.isMainContact && foundVendor) {
          if (foundVendor.contacts) {
            const currentMainContactIndex = foundVendor.contacts.findIndex(
              c => c.isMainContact
            );
            let vendorHasMainContact = false;
            if (currentMainContactIndex !== -1) vendorHasMainContact = true;
            if (vendorHasMainContact)
              foundVendor.contacts[
                currentMainContactIndex
              ].isMainContact = false;
          }
        }
        if (foundVendor) {
          foundVendor.contacts.push(contact);
        }
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.addContactFromProject.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error adding contact from project: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.updateContact.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.updateContact.success): {
        const { vendor, contact: c } = action.payload;
        const vendorId = vendor.id;
        const contactId = c.id;
        const foundVendor = draft.vendorList.find(v => v.id === vendorId);
        if (!foundVendor) break;
        const index = foundVendor.contacts.findIndex(c => c.id === contactId);
        if (index === -1) break;
        const contact = new ContactDto({ ...c });
        if (contact.isMainContact) {
          const currentMainContactIndex = foundVendor.contacts.findIndex(
            c => c.isMainContact
          );
          let vendorHasMainContact = false;
          if (currentMainContactIndex !== -1) vendorHasMainContact = true;
          if (vendorHasMainContact)
            foundVendor.contacts[currentMainContactIndex].isMainContact = false;
        }
        foundVendor.contacts[index] = contact;
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.updateContact.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error updating contact: ${action.payload.message}`);
        break;
      }

      case getType(actions.deleteContact.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.deleteContact.success): {
        const { vendorId, contact: c } = action.payload;
        const vendor = draft.vendorList.find(v => v.id === vendorId);
        if (vendor) {
          const contacts = vendor?.contacts?.filter(vc => vc.id !== c.id);
          vendor.contacts = contacts;
        }
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.deleteContact.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error deleting contact: ${action.payload.message}`);
        break;
      }

      case getType(actions.moveContact.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }
      case getType(actions.moveContact.success): {
        const { oldVendorId, newVendorId, contact: c } = action.payload;
        // Remove Offices and Scope of Work if we are moving the contact
        if (oldVendorId !== newVendorId) {
          c.vendorLocationId = "";
        }
        // Handle contact removal
        const vendorToDeleteFrom = draft.vendorList.find(
          v => v.id === oldVendorId
        );

        if (vendorToDeleteFrom) {
          const updatedContacts = vendorToDeleteFrom?.contacts.filter(
            vc => vc.id !== c.id
          );
          vendorToDeleteFrom.contacts = updatedContacts;
        }

        // Handle contact addition
        const vendorToAddIn = draft.vendorList.find(v => v.id === newVendorId);

        if (vendorToAddIn) {
          // Change VendorProductId to match with the product id of the new vendor.
          const vendorToAddInProductsLookup = keyBy(
            vendorToAddIn?.products,
            p => p.product?.id
          );
          c.products?.forEach(p => {
            if (!p.productTypeId) return;
            p.vendorProductId = vendorToAddInProductsLookup[p.productTypeId].id;
          });

          // add the contact to the new vendor
          vendorToAddIn.contacts.push(c);
        }
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.moveContact.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(`Error moving contact: ${action.payload.message}`);
        break;
      }

      case getType(actions.loadInteractionHistoryLogsByVendorContactId.request):
      case getType(actions.createInteractionHistoryLog.request):
      case getType(actions.updateInteractionHistoryLog.request):
      case getType(actions.deleteInteractionHistoryLog.request): {
        draft.interactionsLoaded = false;
        draft.interactionsLoading = true;
        break;
      }

      case getType(
        actions.loadInteractionHistoryLogsByVendorContactId.success
      ): {
        draft.interactionHistoryLogsForOneContact = action.payload;
        draft.interactionsLoading = false;
        draft.interactionsLoaded = true;
        break;
      }

      case getType(
        actions.loadInteractionHistoryLogsByVendorContactId.failure
      ): {
        draft.interactionsLoading = false;
        draft.interactionsLoaded = true;
        draft.errors.push(
          `Error getting Interaction History Logs: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.createInteractionHistoryLog.success): {
        draft.interactionHistoryLogsForOneContact.push(action.payload);
        draft.interactionsLoading = false;
        draft.interactionsLoaded = true;
        draft.showAddInteractionModal = false;
        break;
      }

      case getType(actions.createInteractionHistoryLog.failure): {
        draft.interactionsLoading = false;
        draft.interactionsLoaded = true;
        draft.errors.push(
          `Error creating Interaction History Log: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.updateInteractionHistoryLog.success): {
        const { id, interactionHistoryLog } = action.payload;
        const oldInteraction = draft.interactionHistoryLogsForOneContact.find(
          v => v.id === id
        );
        if (oldInteraction) {
          const updatedInteraction = {
            ...oldInteraction,
            ...interactionHistoryLog
          };
          const interactions = draft.interactionHistoryLogsForOneContact.filter(
            v => v.id !== id
          );
          draft.interactionHistoryLogsForOneContact = [
            ...interactions,
            updatedInteraction
          ];
        }
        draft.interactionsLoading = false;
        draft.interactionsLoaded = true;
        draft.showAddInteractionModal = false;
        break;
      }

      case getType(actions.updateInteractionHistoryLog.failure): {
        draft.interactionsLoading = false;
        draft.interactionsLoaded = true;
        draft.errors.push(
          `Error updating Interaction History Log: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.deleteInteractionHistoryLog.success): {
        const deletedId = action.payload;
        const newInteractions = draft.interactionHistoryLogsForOneContact.filter(
          v => v.id !== deletedId
        );
        draft.interactionHistoryLogsForOneContact = newInteractions;
        draft.interactionsLoading = false;
        draft.interactionsLoaded = true;
        break;
      }

      case getType(actions.deleteInteractionHistoryLog.failure): {
        draft.interactionsLoading = false;
        draft.interactionsLoaded = true;
        draft.errors.push(
          `Error deleting Interaction History Log: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.updateLastInteractionDate.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.updateLastInteractionDate.success): {
        const { vendorId, contactId } = action.payload;
        const vendor = draft.vendorList.find(v => v.id === vendorId);
        const vendorContactToUpdate = vendor?.contacts.find(
          c => c.id === contactId
        );
        if (!vendorContactToUpdate) return;
        if (draft.interactionHistoryLogsForOneContact.length === 0) {
          vendorContactToUpdate.lastInteraction = undefined;
        } else {
          const datesOrderedByMostRecent = draft.interactionHistoryLogsForOneContact.toSorted(
            (a, b) => {
              return (
                -1 *
                (new Date(a.interactionDate).valueOf() -
                  new Date(b.interactionDate).valueOf())
              );
            }
          );

          // The contact list table expects the format to be ISO without the "Z". This matches what returns when retrieving the same data from Contacts API.
          vendorContactToUpdate.lastInteraction = datesOrderedByMostRecent[0].interactionDate
            .toISOString()
            .slice(0, -1);
        }
        draft.loaded = true;
        draft.loading = false;
        break;
      }

      case getType(actions.updateLastInteractionDate.failure): {
        draft.loaded = true;
        draft.loading = false;
        draft.errors.push(
          `Error updating Last Interaction Date ${action.payload.message}`
        );
        break;
      }

      case getType(actions.searchContacts.request): {
        draft.searched = false;
        draft.searching = true;
        break;
      }

      case getType(actions.searchContacts.success): {
        const contacts = action.payload;
        draft.searchResults = contacts;
        draft.searched = true;
        draft.searching = false;
        break;
      }

      case getType(actions.searchContacts.failure): {
        draft.searching = false;
        draft.searched = false;
        draft.errors.push(
          `Error searching contacts: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.setShowSearchModal): {
        draft.showSearchModal = action.payload.isOpen;
        break;
      }

      case getType(actions.addMinorityStatus.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.addMinorityStatus.success): {
        const { minorityStatus, vendorId } = action.payload;
        draft.minorityTypes.push(action.payload.minorityStatus);
        const vendor = draft.vendorList.find(v => v.id === vendorId);
        if (vendor) {
          vendor.minorityStatuses = [
            ...vendor.minorityStatuses,
            minorityStatus
          ];
        }
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.addMinorityStatus.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error adding Minority Status: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.updateMinorityStatus.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.updateMinorityStatus.success): {
        const { minorityStatus: ms, vendorId, statusId } = action.payload;
        const vendor = draft.vendorList.find(v => v.id === vendorId);
        if (!vendor) break;
        const index = vendor.minorityStatuses.findIndex(
          ms => ms.id === statusId
        );
        if (index === -1) break;
        const minorityStatus = new MinorityStatusDto({ ...ms });
        const minorityStatuses = [...vendor.minorityStatuses];
        minorityStatuses[index] = minorityStatus;
        vendor.minorityStatuses = [...minorityStatuses];
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.updateMinorityStatus.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error updating Minority Status: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.deleteMinorityStatus.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.deleteMinorityStatus.success): {
        const { vendorId, statusId } = action.payload;
        const vendor = draft.vendorList.find(v => v.id === vendorId);
        if (!vendor) break;
        const minorityStatuses = vendor.minorityStatuses.filter(
          m => m.id !== statusId
        );
        vendor.minorityStatuses = [...minorityStatuses];
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.deleteMinorityStatus.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error deleting Minority Status: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.updateCompanyTypes.success): {
        const companyTypes = action.payload;
        draft.companyTypes = companyTypes;
        break;
      }

      case getType(actions.addMinorityType.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.addMinorityType.success): {
        draft.minorityTypes.push(action.payload);
        draft.minorityTypes.sort(
          (a, b) => a.code?.localeCompare(b.code ?? "") ?? 0
        );
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.addMinorityType.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error adding Minority Type: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.updateMinorityType.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.updateMinorityType.success): {
        const { minorityType, minorityTypeId } = action.payload;
        const index = draft.minorityTypes.findIndex(
          t => t.id === minorityTypeId
        );
        if (index === -1) break;
        const updatedMinorityType: IMinorityTypeDto = { ...minorityType };
        const minorityTypes = [...draft.minorityTypes];
        minorityTypes[index] = updatedMinorityType;
        draft.minorityTypes = [...minorityTypes].sort(
          (a, b) => a.code?.localeCompare(b.code ?? "") ?? 0
        );
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.updateMinorityType.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error updating Minority Type: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.addProductType.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.addProductType.success): {
        draft.productTypes.push(action.payload);
        draft.productTypes.sort(
          (a, b) => a.code?.localeCompare(b.code ?? "") ?? 0
        );
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.addProductType.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error adding Product Type: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.updateProductType.request): {
        draft.loaded = false;
        draft.loading = true;
        break;
      }

      case getType(actions.updateProductType.success): {
        const { productType, productTypeId } = action.payload;
        const index = draft.productTypes.findIndex(t => t.id === productTypeId);
        if (index === -1) break;
        const productTypes = [...draft.productTypes];
        productTypes[index] = productType;
        draft.productTypes = [...productTypes].sort(
          (a, b) => a.code?.localeCompare(b.code ?? "") ?? 0
        );
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.updateProductType.failure): {
        draft.loading = false;
        draft.loaded = true;
        draft.errors.push(
          `Error updating Product Type: ${action.payload.message}`
        );
        break;
      }

      case getType(actions.syncContactToProjects.request): {
        draft.loading = true;
        draft.loaded = false;
        break;
      }

      case getType(actions.syncContactToProjects.success): {
        draft.loading = false;
        draft.loaded = true;
        break;
      }

      case getType(actions.syncContactToProjects.failure): {
        draft.loading = false;
        draft.loaded = true;
        break;
      }
    }
  });
};

export type SelectorState = StateSlice;

// Selectors
const getVendorList = ({ contacts }: SelectorState) => contacts.vendorList;
const geDetailedVendor = ({ contacts }: SelectorState) => contacts.vendorList;
const getMinorityTypes = ({ contacts }: SelectorState) =>
  contacts.minorityTypes;
const getProductTypes = ({ contacts }: SelectorState) => contacts.productTypes;
const getInteractions = ({ contacts }: SelectorState) =>
  contacts.interactionHistoryLogsForOneContact;
const getErrors = ({ contacts }: SelectorState) => contacts.errors;
const getLoading = ({ contacts }: SelectorState) => contacts.loading;
const getLoaded = ({ contacts }: SelectorState) => contacts.loaded;
const getInteractionsLoading = ({ contacts }: SelectorState) =>
  contacts.interactionsLoading;
const getInteractionsLoaded = ({ contacts }: SelectorState) =>
  contacts.interactionsLoaded;
const getShowModal = ({ contacts }: SelectorState) => contacts.showModal;
const getShowMoveContactModal = ({ contacts }: SelectorState) =>
  contacts.showMoveContactModal;
const getShowInteractionsModal = ({ contacts }: SelectorState) =>
  contacts.showInteractionsModal;
const getShowAddInteractionModal = ({ contacts }: SelectorState) =>
  contacts.showAddInteractionModal;
const getCompanyTypes = ({ contacts }: SelectorState) => contacts.companyTypes;
const getSearchResults = ({ contacts }: SelectorState) =>
  contacts.searchResults;
const getIsSearching = ({ contacts }: SelectorState) => contacts.searching;
const getShowSearchModal = ({ contacts }: SelectorState) =>
  contacts.showSearchModal;

const getVendorListSortedByCode = createSelector(
  [getVendorList],
  vendorList => {
    return sortBy(vendorList, ["code"]);
  }
);

const getVendorCodeList = createSelector([getVendorList], vendorList => {
  return vendorList.map(vendor => vendor.code);
});

const getVendorCodeIdDict = createSelector([getVendorList], vendorList =>
  Object.assign({}, ...vendorList.map(v => ({ [v.code]: v.id })))
);

const getVendorDataOptions = createSelector(
  [getVendorListSortedByCode],
  vendorList => {
    return vendorList.map(vendor => {
      const firstLocation = getVendorFirstLocation(vendor);
      return {
        id: vendor.id,
        code: vendor.code,
        name: vendor.name,
        phoneNumber: firstLocation?.phoneNumber,
        webAddress: vendor.webAddress,
        type: vendor.type,
        products: vendor.products,
        offices: vendor.locations
      };
    });
  }
);

const getMinorityStatusOptions = createSelector(
  [getMinorityTypes],
  minorityTypes => {
    return minorityTypes.map(mt => ({
      code: mt.code,
      display: mt.code ?? "",
      value: mt
    }));
  }
);
const getSearchResultsSorted = createSelector(
  [getSearchResults],
  searchResults => {
    return sortBy(
      searchResults,
      result =>
        `${result.firstName?.toLocaleUpperCase()} ${result.lastName?.toLocaleUpperCase()}`
    );
  }
);

const getVendorIdCompanyTypeDict = createSelector([getVendorList], vendors => {
  const reducer = (prev: any, { id, type }: IVendorDtoV1Response) => ({
    ...prev,
    [`${id}`]: type
  });
  const result = vendors.reduce<Record<string, CompanyTypeDto | null>>(
    reducer,
    {}
  );
  return result;
});

const getContactIdProductTypeDict = createSelector([getVendorList], vendors => {
  const contactList = vendors.map(v => v.contacts).flat();
  const reducer = (prev: any, { id, products }: IContactDto) => ({
    ...prev,
    [`${id}`]: products ?? []
  });
  const result = contactList.reduce<Record<string, IContactDto | null>>(
    reducer,
    {}
  );
  return result;
});

export const selectors = {
  getVendorList,
  geDetailedVendor,
  getMinorityTypes,
  getProductTypes,
  getInteractions,
  getInteractionsLoading,
  getInteractionsLoaded,
  getErrors,
  getLoading,
  getLoaded,
  getShowModal,
  getShowMoveContactModal,
  getShowInteractionsModal,
  getShowAddInteractionModal,
  getVendorListSortedByCode,
  getVendorDataOptions,
  getMinorityStatusOptions,
  getCompanyTypes,
  getSearchResults,
  getSearchResultsSorted,
  getIsSearching,
  getShowSearchModal,
  getVendorCodeList,
  getVendorCodeIdDict,
  getVendorIdCompanyTypeDict,
  getContactIdProductTypeDict
};

const filterDuplicateProducts = (vendorList: IVendorDtoV1Response[]) => {
  return vendorList.map(vendor => {
    const filtered = vendor.products.filter((value, index, self) => {
      return (
        self.findIndex(p => p.product?.code === value.product?.code) === index
      );
    });
    return { ...vendor, products: filtered };
  });
};

const getVendorFirstLocation = (vendor: IVendorDtoV1Response) => {
  if (!vendor || !vendor.locations) return;
  const sortedLocations = sortBy(vendor?.locations, function (loc) {
    return loc.nickname.toLowerCase();
  });
  return sortedLocations[0];
};
